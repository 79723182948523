import React, { useEffect, useState } from 'react';
import numeral from "numeral";
import '../style/Table.css';

function Table(props) {
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const getCountriesData = async () => {
            fetch("https://api.covid19api.com/summary")
            .then((response) => response.json())
                .then((data) => {
                    let tableData = buildTableData(data)
                    tableData = sortData(tableData)
                    setTableData(Object.entries(tableData));
                }    
            );
        };
        getCountriesData();
    }, []);
    
    const buildTableData = (data) => {
        let tabledata = []

        for (let record in data.Countries) {
            let newDataPoint = {
                country: data.Countries[record].Country,
                cases: data.Countries[record].TotalConfirmed
              };

            tabledata.push(newDataPoint)
        }
        return tabledata;
    }

    const sortData = (data) => {
        let sortedData = [...data];
        sortedData.sort((a, b) => {
          if (a.cases > b.cases) {
            return -1;
          } else {
            return 1;
          }
        });
        return sortedData;
    };    

    return (
        <div className="table">
            {tableData.map((country) => (
                <tr>
                    <td>{country[1].country}</td>
                    <td>
                        <strong>{numeral(country[1].cases).format("0,0")}</strong>
                    </td>
                </tr>
            ))}
        </div>
    );
}

export default Table;