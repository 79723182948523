import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import numeral from "numeral";
import '../style/Linegraph.css';

function LineGraph({ casesType, activeCountry }) {
    const [singaporeData, setSingaporeData] = useState({});
    const [countryData, setCountryData] = useState({});
    const [lastUpdated, setLastUpdated] = useState('');
    const [stepSize, setStepSize] = useState(100);
    const passNumDays = 60;

    const buildChartData = (data, type, days) => {
        let xAxis = [];
        let yAxis = [];
    
        if (type === 'new_confirmed') {
            for (let record in data.data.timeline) {
                //unshift push to front of array
                xAxis.unshift(data.data.timeline[record].date)
                yAxis.unshift(data.data.timeline[record].new_confirmed)
                setStepSize(100);
            }                            
        }
        else if (type === 'death') {
            for (let record in data.data.timeline) {
                //unshift push to front of array
                xAxis.unshift(data.data.timeline[record].date)
                yAxis.unshift(data.data.timeline[record].deaths)
                setStepSize(10);
            }                            
        }
        else if (type === 'confirmed') {
            for (let record in data.data.timeline) {
                //unshift push to front of array
                xAxis.unshift(data.data.timeline[record].date)
                yAxis.unshift(data.data.timeline[record].confirmed)
                setStepSize(100);
            }
        }
        else {
            console.log("Type Error");
        }

        return [xAxis.splice(xAxis.length-days, xAxis.length), yAxis.splice(yAxis.length-days, yAxis.length)];
    };
    
    const data = {
        labels: singaporeData[0],
        datasets: [
            {
                label: "Singapore (SG)",
                data: singaporeData[1],
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: '#08234f'
            },
            {
                label: `${activeCountry.country}`,
                data: countryData[1],
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "#dc8aeb"
            }
        ]
    };

    const options = {
        maintainAspectRatio: true,
        elements: {
          point: {
            radius: 3.0,
          },
        },
        scales: {
            x: {
                grid: {
                    display: true,
                },
                title: {
                    display: true,
                    text: "Past 60 Days",
                    font: {
                        size: 20,
                        weight: "bold",
                    }
                }
            },
            y: {
                grid: {
                    display: true,
                },
                title: {
                    display: true,
                    text: "Number of Cases",
                    font: {
                        size: 20,
                        weight: "bold",
                    }
                },
                ticks: {
                    stepSize: stepSize,

                    callback: function (value, index, values) {
                        return numeral(value).format("0 a");
                    }
                }
            },
        },
        layout: {
            padding: {
                right: 20
            }
        }
    };
  
    useEffect(() => {
        const fetchData = async () => {
            await fetch("https://corona-api.com/countries/sg")
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    let chartData = buildChartData(data, casesType, passNumDays);
                    setSingaporeData(chartData);
                    var date = new Date(data.data.timeline[0].updated_at).toLocaleDateString('en-US',
                    {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: "numeric",
                        minute: "numeric"
                    }).replace(',', '');
                setLastUpdated(date);
                }    
            )
        };
        fetchData();
    }, [casesType]);

    useEffect(() => {
        const fetchData = async () => {
            await fetch("https://corona-api.com/countries/" + activeCountry.code)
                .then((response) => {
                    return response.json();
                }).then((tdata) => {  
                    let chartData = buildChartData(tdata, casesType, passNumDays);
                    setCountryData(chartData);
                }
            )
        };
        
        fetchData();
    }, [activeCountry]);    
    
    return (
        <div>
            <Line data={data} options={options} />
            <p style={{
                textAlign: 'right',
                fontSize: '10px',
                fontWeight: "bold"}}>
                Last Updated: {lastUpdated}
            </p>
        </div>
    );
  }
  
export default LineGraph;
