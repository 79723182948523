import Main from './components/Main';
import './style/App.css';

function App() {
  return (
    <div className="App">
      <Main/>
    </div>
  );
}

export default App;
