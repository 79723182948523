import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import '../style/CountrySelect.css';


function CountrySelect(props) {

    const [countries, setCountries] = useState({});

    useEffect(() => {
        const getCountriesData = async () => {
            fetch("https://api.covid19api.com/summary")
            .then((response) => response.json())
                .then((data) => {
                    let countryList = buildCountryList(data)
                    setCountries(countryList);
                }    
            );
        };
        getCountriesData();
    }, []);

    const buildCountryList = (data) => {
        let countryList = []

        for (let record in data.Countries) {
            let newDataPoint = {
                country: data.Countries[record].Country,
                code: data.Countries[record].CountryCode
            };
            countryList.push(newDataPoint)
        }
        return countryList;
    }

    const handleChange = ((event, newValue) => {
        if (event.code !== null) {
            props.ActiveCountry(newValue);
        }
    })
    
    return (
        <div>
            <Autocomplete
                onChange={(event, newValue) => {
                    if (newValue !== null)
                    handleChange(event, newValue)
                }}                
                sx={{ width: 250 }}
                options={countries}
                autoHighlight
                getOptionLabel={(option) => option.country}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                            loading="lazy"
                            width="20"
                            src = {`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt = ""
                        />
                        {option.country} ({option.code})
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Choose country to compare"
                        inputProps={{
                            ...params.inputProps,    
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }
                    }
                    />
                )}
            />
        </div>
    )
}

export default CountrySelect;