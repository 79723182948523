import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid } from "@material-ui/core";
import numeral from "numeral";
import '../style/InfoBox.css';

function InfoBox({ title, casesType, active, ...props }) {
    
    const [infoData, setInfoData] = useState('');
    const [delta, setDelta] = useState('');

    const buildInfoData = (data) => {
        let latest;
        let second_latest;
        let diff;

        if (casesType === "new_confirmed") {
            latest = data.data.timeline[0].new_confirmed
            second_latest = data.data.timeline[1].new_confirmed
            diff = latest - second_latest
            setInfoData(latest);
            diff >= 0 ? setDelta(numeral(diff).format('+0,0')) : setDelta(numeral(diff).format('0,0'))
        }
        else if (casesType === "death") {
            latest = data.data.timeline[0].deaths;
            second_latest = data.data.timeline[1].deaths
            diff = latest - second_latest
            setInfoData(latest);
            diff >= 0 ? setDelta(numeral(diff).format('+0,0')) : setDelta(numeral(diff).format('0,0'))
        }
        else if (casesType === "confirmed") {
            latest = data.data.timeline[0].confirmed;
            setInfoData(latest);
        }
        else {
            console.log("Unknown casesType")
            return;
        }
    }

    useEffect(() => {
        const getCountriesData = async () => {
            fetch("https://corona-api.com/countries/sg")
            .then((response) => response.json())
                .then((data) => {
                    buildInfoData(data)
                }    
            );
        };
        getCountriesData();
    });
    
    return (
        <div>
            <Card
                onClick={props.onClick}
                className={`infoBox ${active && "infoBox--selected"}`}
            >
                <CardContent>
                    <h1 className="infoBox__title">
                        {title}
                    </h1>
                    <h2 className="infoBox__cases" >
                        <Grid container direction="row" spacing={3} alignItems="flex-end" justifyContent="center">
                            <Grid item>
                                {infoData}
                            </Grid>
                            <Grid item>
                                {casesType !== 'confirmed' &&
                                    <h3 className={`${delta > 0 ? "infoBox__text--red" : "infoBox__text--green"}`}>
                                    {delta}
                                    </h3>}
                                {casesType !== 'confirmed' && <p style={{fontSize:'10px', color:'black'}}>from last record</p>}
                            </Grid>
                        </Grid>
                    </h2>
                </CardContent>
            </Card>
        </div>
    )
}

export default InfoBox;