import React, { useState } from 'react';
import { Card, CardContent, Grid } from "@material-ui/core";
import LineGraph from './Linegraph';
import Table from './Table';
import InfoBox from './InfoBox';
import CountrySelect from './CountrySelect';
import '../style/main.css';

function Main() {
    const [caseType, setCaseType] = useState("new_confirmed");
    const [activeCountry, setActiveCountry] = useState({country: "Singapore", code: "SG"});

    const setNewConfirmed = (() => {
        setCaseType("new_confirmed")
    })

    const setDeath = (() => {
        setCaseType("death")
    })

    const setConfirmed = (() => {
        setCaseType("confirmed")
    })

    const changeActiveCountry = ((country) => {
        setActiveCountry(country)
    })
    
    return (
        <div className="main">
            <div className="main__header">
                <h1>Covid19 Tracker</h1>
                <h2 style={{fontSize:"20px"}}>Cases in Singapore</h2>
            </div>

            <Grid container spacing={2} direction="row" alignItems="flex-start" justifyContent="center">
                <Grid item md={3}>
                    <Card>
                        <CardContent>
                            <div className="table__information">
                                <h3>Global Live Cases by Country</h3>
                                <Table/>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item md={8}>
                    <Card>
                        <CardContent>  
                            <div className="main__information">
                                <Grid container spacing={2} direction="row" alignItems="flex-start" justifyContent="center">
                                    <Grid item md={4}>
                                    </Grid>
                                    <Grid item md={4}>
                                    </Grid>
                                    <Grid item md={4} >
                                        <CountrySelect ActiveCountry={changeActiveCountry}/>
                                    </Grid>    
                                </Grid>                                
                                <LineGraph casesType={caseType} activeCountry={activeCountry}/>
                            </div>
                        </CardContent>
                    </Card>
                    <div style={{paddingTop: 10}}>
                        <Grid container spacing={2} direction="row" alignItems="flex-start" justifyContent="center">
                            <Grid item md={4}>
                                <InfoBox
                                    title="New Confirmed Cases"
                                    casesType="new_confirmed"
                                    onClick={setNewConfirmed}
                                    active={caseType === "new_confirmed"}/>
                            </Grid>
                            <Grid item md={4}>
                                <InfoBox
                                    title="Deaths"
                                    casesType="death"
                                    onClick={setDeath}
                                    active={caseType === "death"}/>
                            </Grid>
                            <Grid item md={4}>
                                <InfoBox
                                    title="Total Confirmed Cases"
                                    casesType="confirmed"
                                    onClick={setConfirmed}
                                    active={caseType === "confirmed"}/>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Main;